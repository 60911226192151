import React from "react";
import { useSelector } from "react-redux";
import { contractActions, contractSelectors } from "store/contract";
import { Grid, Card, Table, Button } from "@skyportal/ui-kit";
import useFetchDetails from "./useFetchDetails";
import useColumnConfig from "./useColumnConfig";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store";

export const DomainList: React.FC = () => {
  const { t } = useTranslation("dm-common");
  const appDispatch = useAppDispatch();
  const isFetching = useFetchDetails();
  const contractMappings = useSelector(contractSelectors.getContractMappings);
  const columns = useColumnConfig();
  const handleAddDomainClick = () => appDispatch(contractActions.setAddMappingModalOpenAC(true));

  return (
    <Card data-testid="mappingList" loading={isFetching} padding="medium">
      <Grid container direction="column" spacing={2} alignItems="stretch">
        <Grid item container justify="flex-end">
          <Grid item>
            <Button onClick={handleAddDomainClick}>{t("dm-common:Add Domain")}</Button>
          </Grid>
        </Grid>
        <Grid item>
          <Table
            size="medium"
            rowKey="id"
            dataSource={contractMappings}
            columns={columns}
            noDataText={t("No domains")}
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export default React.memo(DomainList);
