import {
  ADD_MAPPING_REQUEST,
  ADD_MAPPING_REQUEST_FAILURE,
  ADD_MAPPING_REQUEST_SUCCESS,
  CONTRACT_DETAILS_REQUEST,
  CONTRACT_DETAILS_REQUEST_FAILURE,
  CONTRACT_DETAILS_REQUEST_SUCCESS,
  ContractAction,
  ContractStateSlice,
  DELETE_CONTRACT_MAPPING_REQUEST,
  DELETE_CONTRACT_MAPPING_REQUEST_FAILURE,
  DELETE_CONTRACT_MAPPING_REQUEST_SUCCESS,
  SET_ADD_MAPPING_MODAL_OPEN,
  SET_DELETE_CONTRACT_MAPPING_ID,
  SET_UPDATE_CONTRACT_MAPPING_ID,
  UPDATE_CONTRACT_MAPPING_REQUEST,
  UPDATE_CONTRACT_MAPPING_REQUEST_FAILURE,
  UPDATE_CONTRACT_MAPPING_REQUEST_SUCCESS,
} from "./contract.types";
import { RequestStatus } from "types/common.types";

export const initState: ContractStateSlice = {
  detailed: null,
  getRequestStatus: RequestStatus.UNCALLED,
  addMappingRequest: RequestStatus.UNCALLED,
  deleteMappingRequest: RequestStatus.UNCALLED,
  updateMappingRequest: RequestStatus.UNCALLED,
  addMappingRequestError: null,
  addMappingModalOpen: false,
  deleteMappingId: null,
  deleteMappingRequestError: null,
  updateMappingId: null,
  updateMappingRequestError: null,
};

export default function ContractReducer(
  state: ContractStateSlice = initState,
  action: ContractAction
): ContractStateSlice {
  switch (action.type) {
    case CONTRACT_DETAILS_REQUEST: {
      return {
        ...state,
        getRequestStatus: RequestStatus.PENDING,
        detailed: null,
      };
    }
    case CONTRACT_DETAILS_REQUEST_SUCCESS: {
      return {
        ...state,
        getRequestStatus: RequestStatus.SUCCESS,
        detailed: action.payload,
      };
    }
    case CONTRACT_DETAILS_REQUEST_FAILURE: {
      return {
        ...state,
        getRequestStatus: RequestStatus.FAILURE,
      };
    }
    case ADD_MAPPING_REQUEST: {
      return {
        ...state,
        addMappingRequest: RequestStatus.PENDING,
        addMappingRequestError: null,
      };
    }
    case ADD_MAPPING_REQUEST_SUCCESS: {
      return {
        ...state,
        addMappingRequest: RequestStatus.SUCCESS,
        addMappingModalOpen: false,
      };
    }
    case ADD_MAPPING_REQUEST_FAILURE: {
      return {
        ...state,
        addMappingRequest: RequestStatus.FAILURE,
        addMappingRequestError: action.payload,
      };
    }
    case SET_ADD_MAPPING_MODAL_OPEN: {
      return {
        ...state,
        addMappingRequestError: null,
        addMappingModalOpen: action.payload,
        addMappingRequest: RequestStatus.UNCALLED,
      };
    }
    case DELETE_CONTRACT_MAPPING_REQUEST: {
      return {
        ...state,
        deleteMappingRequest: RequestStatus.PENDING,
        deleteMappingRequestError: null,
      };
    }
    case DELETE_CONTRACT_MAPPING_REQUEST_SUCCESS: {
      return {
        ...state,
        deleteMappingRequest: RequestStatus.SUCCESS,
        deleteMappingId: null,
        detailed: state.detailed
          ? {
              ...state.detailed,
              mappings: state.detailed.mappings.filter((item) => item.id !== action.payload) || [],
            }
          : null,
      };
    }
    case DELETE_CONTRACT_MAPPING_REQUEST_FAILURE: {
      return {
        ...state,
        deleteMappingRequest: RequestStatus.FAILURE,
        deleteMappingRequestError: action.payload,
      };
    }
    case SET_DELETE_CONTRACT_MAPPING_ID: {
      return {
        ...state,
        deleteMappingRequestError: null,
        deleteMappingId: action.payload,
        deleteMappingRequest: RequestStatus.UNCALLED,
      };
    }
    case UPDATE_CONTRACT_MAPPING_REQUEST: {
      return {
        ...state,
        updateMappingRequestError: null,
        updateMappingRequest: RequestStatus.PENDING,
      };
    }
    case UPDATE_CONTRACT_MAPPING_REQUEST_SUCCESS: {
      return {
        ...state,
        updateMappingRequest: RequestStatus.SUCCESS,
        updateMappingId: null,
      };
    }
    case UPDATE_CONTRACT_MAPPING_REQUEST_FAILURE: {
      return {
        ...state,
        updateMappingRequest: RequestStatus.FAILURE,
        updateMappingRequestError: action.payload,
      };
    }
    case SET_UPDATE_CONTRACT_MAPPING_ID: {
      return {
        ...state,
        updateMappingRequestError: null,
        updateMappingId: action.payload,
        updateMappingRequest: RequestStatus.UNCALLED,
      };
    }
    default:
      return state;
  }
}
