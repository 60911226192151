import * as React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { contractsActions } from "store/contracts";
import { useAppDispatch } from "store";
import { ContractListItem } from "types/contracts.types";
import { Grid, Button } from "@skyportal/ui-kit";

interface ContractActionCellProps {
  record: ContractListItem;
}

export const ContractActionCell: React.FC<ContractActionCellProps> = ({ record }) => {
  const { t } = useTranslation("dm-common");
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const handleDeleteClick = () => appDispatch(contractsActions.setContractIdToDeleteAC(record.contractId));
  const handleDomainsClick = () => history.push(`/backup/contracts/${record.contractId}`);

  return (
    <Grid container spacing={1} alignItems="center" justify="flex-end">
      <Grid item>
        <Button size="small" onClick={handleDomainsClick}>
          {t("Domains", "Domains")}
        </Button>
      </Grid>
      <Grid item>
        <Button type="secondary" size="small" onClick={handleDeleteClick}>
          {t("Delete", "Delete")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ContractActionCell;
