import { AxiosError } from "axios";

export const getAxiosValidationCode = (error: AxiosError<any>): [number, string] | [null, null] => {
  if (!error || !error.isAxiosError) {
    return [null, null];
  }
  try {
    const {
      msg,
      data: { code },
    } = error.response?.data;
    return [code, msg];
  } catch (error) {
    console.error(error);
    return [null, null];
  }
};
