import { ContractListItem, ContractSortFiled } from "types/contracts.types";
import { ApiErrorCode, RequestStatus, SortOrder } from "types/common.types";

export interface ContractsStateSlice {
  readonly listRequestStatus: RequestStatus;
  readonly createRequest: RequestStatus;
  readonly deleteRequestStatus: RequestStatus;
  readonly createRequestErrorCode: ApiErrorCode;
  readonly deleteRequestErrorCode: ApiErrorCode;
  readonly contractIdToDelete: string | null;
  readonly list: ContractListItem[];
  readonly sortOrder: SortOrder;
  readonly sortField: ContractSortFiled;
  readonly page: number;
  readonly perPage: number;
  readonly search: string;
}

export const CONTRACT_LIST_REQUEST = "CONTRACTS/CONTRACT_LIST_REQUEST";
export type ContractListRequestAction = {
  type: typeof CONTRACT_LIST_REQUEST;
};
export type ContractListRequestAC = () => ContractListRequestAction;

export const CONTRACT_LIST_REQUEST_SUCCESS = "CONTRACTS/CONTRACT_LIST_REQUEST_SUCCESS";
export type ContractListRequestSuccessAction = {
  type: typeof CONTRACT_LIST_REQUEST_SUCCESS;
  payload: ContractListItem[];
};
export type ContractListRequestSuccessAC = (payload: ContractListItem[]) => ContractListRequestSuccessAction;

export const CONTRACT_LIST_REQUEST_FAILED = "CONTRACTS/CONTRACT_LIST_REQUEST_FAILED";
export type ContractListRequestFailedAction = {
  type: typeof CONTRACT_LIST_REQUEST_FAILED;
};
export type ContractListRequestFailedAC = () => ContractListRequestFailedAction;

export const SET_SORT_FIELD = "CONTRACTS/SET_SORT_FIELD";
export type SetSortFieldAction = {
  type: typeof SET_SORT_FIELD;
  payload: ContractSortFiled;
};
export type SetSortFieldAC = (sortField: ContractSortFiled) => SetSortFieldAction;

export const SET_CONTRACTS_PAGE = "CONTRACTS/SET_PAGE";
export type SetContractsPageAction = {
  type: typeof SET_CONTRACTS_PAGE;
  payload: number;
};
export type SetContractsPageAC = (page: number) => SetContractsPageAction;

export const SET_CONTRACTS_PER_PAGE = "CONTRACTS/SET_CONTRACTS_PER_PAGE";
export type SetContractsPerPageAction = {
  type: typeof SET_CONTRACTS_PER_PAGE;
  payload: number;
};
export type SetContractsPerPageAC = (page: number) => SetContractsPerPageAction;

export const SET_CONTRACTS_SEARCH = "CONTRACTS/SET_CONTRACTS_SEARCH";
export type SetContractsSearchAction = {
  type: typeof SET_CONTRACTS_SEARCH;
  payload: string;
};
export type SetContractsSearchAC = (page: string) => SetContractsSearchAction;

export const CONTRACT_CREATE_REQUEST = "CONTRACTS/CONTRACT_CREATE_REQUEST";
export type ContractCreateRequestAction = {
  type: typeof CONTRACT_CREATE_REQUEST;
};
export type ContractCreateRequestAC = () => ContractCreateRequestAction;

export const CONTRACT_CREATE_REQUEST_SUCCESS = "CONTRACTS/CONTRACT_CREATE_REQUEST_SUCCESS";
export type ContractCreateRequestSuccessAction = {
  type: typeof CONTRACT_CREATE_REQUEST_SUCCESS;
};
export type ContractCreateRequestSuccessAC = () => ContractCreateRequestSuccessAction;

export const CONTRACT_CREATE_REQUEST_FAILURE = "CONTRACTS/CONTRACT_CREATE_REQUEST_FAILURE";
export type ContractCreateRequestFailureAction = {
  type: typeof CONTRACT_CREATE_REQUEST_FAILURE;
  payload: ApiErrorCode;
};
export type ContractCreateRequestFailureAC = (errorCode: ApiErrorCode) => ContractCreateRequestFailureAction;

export const CONTRACT_DELETE_REQUEST = "CONTRACTS/CONTRACT_DELETE_REQUEST";
export type ContractDeleteRequestAction = {
  type: typeof CONTRACT_DELETE_REQUEST;
};
export type ContractDeleteRequestAC = () => ContractDeleteRequestAction;

export const CONTRACT_DELETE_REQUEST_SUCCESS = "CONTRACTS/CONTRACT_DELETE_REQUEST_SUCCESS";
export type ContractDeleteRequestSuccessAction = {
  type: typeof CONTRACT_DELETE_REQUEST_SUCCESS;
  payload: string;
};
export type ContractDeleteRequestSuccessAC = (id: string) => ContractDeleteRequestSuccessAction;

export const CONTRACT_DELETE_REQUEST_FAILURE = "CONTRACTS/CONTRACT_DELETE_REQUEST_FAILURE";
export type ContractDeleteRequestFailureAction = {
  type: typeof CONTRACT_DELETE_REQUEST_FAILURE;
  payload: ApiErrorCode;
};
export type ContractDeleteRequestFailureAC = (errorCode: ApiErrorCode) => ContractDeleteRequestFailureAction;

export const SET_CONTRACT_ID_TO_DELETE = "CONTRACTS/SET_CONTRACT_ID_TO_DELETE";
export type SetContractIdToDeleteAction = {
  type: typeof SET_CONTRACT_ID_TO_DELETE;
  payload: string | null;
};
export type SetContractIdToDeleteAC = (id: string | null) => SetContractIdToDeleteAction;

export type ContractAction =
  | SetContractIdToDeleteAction
  | ContractDeleteRequestAction
  | ContractDeleteRequestSuccessAction
  | ContractDeleteRequestFailureAction
  | ContractCreateRequestAction
  | ContractCreateRequestSuccessAction
  | ContractCreateRequestFailureAction
  | SetSortFieldAction
  | SetContractsPageAction
  | SetContractsPerPageAction
  | SetContractsSearchAction
  | ContractListRequestAction
  | ContractListRequestSuccessAction
  | ContractListRequestFailedAction;
