import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TableProps, Text } from "@skyportal/ui-kit";
import { formatDate, formatTime } from "utils/time";
import { ContractListItem } from "types/contracts.types";
import ContractActionCell from "./ContractActionCell";
import ContractMappingsCell from "./ContractMappingsCell";

const useColumnConfig = () => {
  const { t } = useTranslation("dm-common");

  const columns: TableProps<ContractListItem>["columns"] = useMemo(
    () => [
      {
        dataIndex: "contractId",
        key: "contractId",
        title: t("Contract", "Contract"),
        render: (value: string) => <Text>{value}</Text>,
      },
      {
        dataIndex: "mappings",
        key: "mappings",
        title: t("Domains", "Domains"),
        render: (_: any, record: ContractListItem) => <ContractMappingsCell record={record} />,
      },
      {
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        title: t("Last Updated", "Last Updated"),
        render: (value: string) => (
          <Text>
            {formatDate(value)} {formatTime(value)}
          </Text>
        ),
      },
      {
        dataIndex: "_action",
        key: "actions",
        sortDisabled: true,
        title: t("Actions", "Actions"),
        render: (_: any, record: ContractListItem) => <ContractActionCell record={record} />,
      },
    ],
    [t]
  );

  return columns;
};

export default useColumnConfig;
