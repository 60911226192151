import {
  CONTRACT_CREATE_REQUEST,
  CONTRACT_CREATE_REQUEST_FAILURE,
  CONTRACT_CREATE_REQUEST_SUCCESS,
  CONTRACT_DELETE_REQUEST,
  CONTRACT_DELETE_REQUEST_FAILURE,
  CONTRACT_DELETE_REQUEST_SUCCESS,
  CONTRACT_LIST_REQUEST,
  CONTRACT_LIST_REQUEST_FAILED,
  CONTRACT_LIST_REQUEST_SUCCESS,
  SET_CONTRACTS_PAGE,
  SET_CONTRACTS_PER_PAGE,
  SET_CONTRACTS_SEARCH,
  SET_SORT_FIELD,
  ContractCreateRequestAC,
  ContractCreateRequestFailureAC,
  ContractCreateRequestSuccessAC,
  ContractDeleteRequestAC,
  ContractDeleteRequestFailureAC,
  ContractDeleteRequestSuccessAC,
  ContractListRequestAC,
  ContractListRequestFailedAC,
  ContractListRequestSuccessAC,
  SetContractsPageAC,
  SetContractsPerPageAC,
  SetContractsSearchAC,
  SetSortFieldAC,
  SET_CONTRACT_ID_TO_DELETE,
  SetContractIdToDeleteAC,
} from "./contracts.types";

export const contractListRequestAC: ContractListRequestAC = () => ({
  type: CONTRACT_LIST_REQUEST,
});
export const contractListRequestFailedAC: ContractListRequestFailedAC = () => ({
  type: CONTRACT_LIST_REQUEST_FAILED,
});
export const contractListRequestSuccessAC: ContractListRequestSuccessAC = (payload) => ({
  payload,
  type: CONTRACT_LIST_REQUEST_SUCCESS,
});

export const setSortFieldAC: SetSortFieldAC = (payload) => ({
  type: SET_SORT_FIELD,
  payload,
});

export const setContractsPageAC: SetContractsPageAC = (payload) => ({
  type: SET_CONTRACTS_PAGE,
  payload,
});

export const setContractsPerPageAC: SetContractsPerPageAC = (payload) => ({
  type: SET_CONTRACTS_PER_PAGE,
  payload,
});

export const setContractsSearchAC: SetContractsSearchAC = (payload) => ({
  type: SET_CONTRACTS_SEARCH,
  payload,
});

export const contractCreateRequestAC: ContractCreateRequestAC = () => ({
  type: CONTRACT_CREATE_REQUEST,
});

export const contractCreateRequestSuccessAC: ContractCreateRequestSuccessAC = () => ({
  type: CONTRACT_CREATE_REQUEST_SUCCESS,
});

export const contractCreateRequestFailureAC: ContractCreateRequestFailureAC = (payload) => ({
  type: CONTRACT_CREATE_REQUEST_FAILURE,
  payload,
});

export const contractDeleteRequestAC: ContractDeleteRequestAC = () => ({
  type: CONTRACT_DELETE_REQUEST,
});

export const contractDeleteRequestSuccessAC: ContractDeleteRequestSuccessAC = (payload) => ({
  type: CONTRACT_DELETE_REQUEST_SUCCESS,
  payload,
});

export const contractDeleteRequestFailureAC: ContractDeleteRequestFailureAC = (payload) => ({
  type: CONTRACT_DELETE_REQUEST_FAILURE,
  payload,
});

export const setContractIdToDeleteAC: SetContractIdToDeleteAC = (payload) => ({
  type: SET_CONTRACT_ID_TO_DELETE,
  payload,
});
