import React from "react";
import { Grid, Card } from "@skyportal/ui-kit";
import useFetchContracts from "./useFetchContracts";
import ContractsListFooter from "./ContractsListFooter";
import ContractsListHeader from "./ContractsListHeader";
import { ContractsTable } from "./ContractsTable";

export const ContractList: React.FC = () => {
  const isFetching = useFetchContracts();

  return (
    <Card data-testid="contractList" padding="medium" loading={isFetching}>
      <Grid container direction="column" spacing={2} alignItems="stretch">
        <ContractsListHeader />
        <Grid item>
          <ContractsTable />
        </Grid>
        <ContractsListFooter />
      </Grid>
    </Card>
  );
};

export default React.memo(ContractList);
