import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractSelectors, contractThunks } from "store/contract";
import { RequestStatus } from "types/common.types";

const useFetchDetails = (): boolean => {
  const { id } = useParams<{ id: string }>();
  const thunkDispatch = useThunkDispatch();
  const requestStatus = useSelector(contractSelectors.getRequestDetailsStatus);

  useEffect(() => {
    thunkDispatch(contractThunks.contractDetailsRequest(id));
  }, [id, thunkDispatch]);

  return requestStatus === RequestStatus.PENDING;
};

export default useFetchDetails;
