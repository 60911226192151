import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../store";
import { ApiErrorCode } from "../types/common.types";

const useApiErrorSelector = (selector: (store: RootState) => ApiErrorCode): string => {
  const { t } = useTranslation("apiValidationCodes");
  const apiErrorCode = useSelector(selector);
  return apiErrorCode !== null ? t(String(apiErrorCode), "") : "";
};

export default useApiErrorSelector;
