import config from "config";
import {
  SET_CREATE_CONTRACT_MODAL_OPEN,
  SET_USER_CHECKED,
  SET_USER_PROFILE,
  SetCreateOpenModalOpenAC,
  SetUserCheckedAC,
  SetUserProfileAC,
} from "./app.types";
import { AppThunk } from "store";
import { authClient } from "api/clients";

export const setUserChecked: SetUserCheckedAC = (payload) => ({
  type: SET_USER_CHECKED,
  payload,
});

export const setUserProfile: SetUserProfileAC = (payload) => ({
  type: SET_USER_PROFILE,
  payload,
});

export const setCreateOpenModalOpenAC: SetCreateOpenModalOpenAC = (payload) => ({
  type: SET_CREATE_CONTRACT_MODAL_OPEN,
  payload,
});

export const getUserInfoRequest = (): AppThunk => async (dispatch) => {
  try {
    const userProfile = await authClient.getUserProfile();
    dispatch(setUserProfile(userProfile));

    if (userProfile.is_admin) {
      dispatch(setUserChecked(true));
    } else {
      window.location.href = config.baseUrl;
    }
  } catch (error) {
    console.error(error);
  }
};
