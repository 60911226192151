import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, Button, Alert, Modal } from "@skyportal/ui-kit";
import { useAppDispatch, useThunkDispatch } from "store";
import { contractSelectors, contractActions, contractThunks } from "store/contract";
import { RequestStatus } from "types/common.types";
import useApiErrorSelector from "hooks/useApiErrorSelector";
import { DomainBaseFormFields } from "types/forms";
import DomainBaseForm from "containers/DomainBaseForm/DomainBaseForm";

export const CreateMappingModal: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("createMapping");
  const appDispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const isModalOpen = useSelector(contractSelectors.getAddMappingModalOpen);
  const requestStatus = useSelector(contractSelectors.getAddMappingRequest);
  const apiErrorCodeText = useApiErrorSelector(contractSelectors.getAddMappingRequestError);
  const apiErrors = useMemo(() => ({ domain: apiErrorCodeText }), [apiErrorCodeText]);

  const handleCloseModal = () => appDispatch(contractActions.setAddMappingModalOpenAC(false));

  const handleSubmit = useCallback(
    (data: DomainBaseFormFields) => {
      thunkDispatch(contractThunks.addMappingRequest(id, { domain: data.domain.toLowerCase() }));
    },
    [id, thunkDispatch]
  );

  return (
    <Modal
      loading={requestStatus === RequestStatus.PENDING}
      open={isModalOpen}
      onClose={handleCloseModal}
      title={t("title")}
      data-testid="add-mapping-modal"
      controls={
        <>
          <Button type="secondary" onClick={handleCloseModal}>
            {t("dm-common:Cancel")}
          </Button>
          <Button htmlType="submit" form="add-mapping">
            {t("dm-common:Save")}
          </Button>
        </>
      }
    >
      <Grid container direction="column">
        <Grid item>
          <DomainBaseForm id="add-mapping" onSubmit={handleSubmit} apiErrors={apiErrors} />
        </Grid>
        {requestStatus === RequestStatus.FAILURE && !apiErrorCodeText && (
          <Grid item>
            <Alert type="error">{t("defaultErrorMessage")}</Alert>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default React.memo(CreateMappingModal);
