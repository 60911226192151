import { createSelector } from "reselect";
import { RootState } from "../index";
import { findDomainNameById } from "../../utils/domain";

export const getSlice = (state: RootState) => state.contract;
export const getRequestDetailsStatus = (state: RootState) => state.contract.getRequestStatus;

export const getContractDetails = (state: RootState) => state.contract.detailed;
export const getAddMappingModalOpen = (state: RootState) => state.contract.addMappingModalOpen;
export const getAddMappingRequest = (state: RootState) => state.contract.addMappingRequest;
export const getDeleteMappingRequest = (state: RootState) => state.contract.deleteMappingRequest;
export const getAddMappingRequestError = (state: RootState) => state.contract.addMappingRequestError;
export const getDeleteMappingRequestError = (state: RootState) => state.contract.deleteMappingRequestError;
export const getUpdateMappingRequestError = (state: RootState) => state.contract.updateMappingRequestError;
export const getDeleteMappingId = (state: RootState) => state.contract.deleteMappingId;
export const getUpdateMappingId = (state: RootState) => state.contract.updateMappingId;
export const getUpdateMappingRequest = (state: RootState) => state.contract.updateMappingRequest;

export const getContractMappings = createSelector(getContractDetails, (details) => (details ? details.mappings : []));
export const getDeleteMappingName = createSelector(getContractMappings, getDeleteMappingId, findDomainNameById);
export const getUpdateMappingName = createSelector(getContractMappings, getUpdateMappingId, findDomainNameById);
