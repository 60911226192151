import { UserProfile } from "@skyportal/auth-web-client";

export interface AppStateSlice {
  readonly isUserChecked: boolean;
  readonly userProfile: UserProfile;
  readonly isCreateContractModalOpen: boolean;
}

export const SET_USER_CHECKED = "APP/SET_USER_CHECKED";
export type SetUserCheckedAction = {
  type: typeof SET_USER_CHECKED;
  payload: boolean;
};
export type SetUserCheckedAC = (payload: boolean) => SetUserCheckedAction;

export const SET_USER_PROFILE = "APP/SET_USER_PROFILE";
export type SetUserProfileAction = {
  type: typeof SET_USER_PROFILE;
  payload: UserProfile;
};
export type SetUserProfileAC = (userProfile: UserProfile) => SetUserProfileAction;
export type thunk = () => void;

export const SET_CREATE_CONTRACT_MODAL_OPEN = "APP/SET_CREATE_CONTRACT_MODAL_OPEN";
export type SetCreateOpenModalOpenAction = {
  type: typeof SET_CREATE_CONTRACT_MODAL_OPEN;
  payload: boolean;
};
export type SetCreateOpenModalOpenAC = (open: boolean) => SetCreateOpenModalOpenAction;

export type AppAction = SetUserCheckedAction | SetCreateOpenModalOpenAction | SetUserProfileAction;
