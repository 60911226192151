import * as React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ContractsPage from "pages/ContractsPage";
import ContractPage from "pages/ContractPage";

export const AppRouter: React.FC = () => {
  return (
    <Switch>
      <Route path="/backup/contracts" exact component={ContractsPage} />
      <Route path="/backup/contracts" exact component={ContractsPage} />
      <Route path="/backup/contracts/:id" exact component={ContractPage} />
      <Route path="*">
        <Redirect to="/backup/contracts" />
      </Route>
    </Switch>
  );
};

export default AppRouter;
