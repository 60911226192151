import React from "react";
import { SplashScreen } from "@skyportal/ui-kit";
import useCheckUser from "../../hooks/useCheckUser";

const SpinnerRenderer: React.FC = ({ children }) => {
  const isUserChecked = useCheckUser();

  if (!isUserChecked) {
    return <SplashScreen />;
  }

  return <>{children}</>;
};

export default SpinnerRenderer;
