import {
  CONTRACT_CREATE_REQUEST,
  CONTRACT_CREATE_REQUEST_FAILURE,
  CONTRACT_CREATE_REQUEST_SUCCESS,
  CONTRACT_DELETE_REQUEST,
  CONTRACT_DELETE_REQUEST_FAILURE,
  CONTRACT_DELETE_REQUEST_SUCCESS,
  CONTRACT_LIST_REQUEST,
  CONTRACT_LIST_REQUEST_FAILED,
  CONTRACT_LIST_REQUEST_SUCCESS,
  ContractAction,
  ContractsStateSlice,
  SET_CONTRACT_ID_TO_DELETE,
  SET_CONTRACTS_PAGE,
  SET_CONTRACTS_PER_PAGE,
  SET_CONTRACTS_SEARCH,
  SET_SORT_FIELD,
} from "./contracts.types";
import { RequestStatus } from "types/common.types";
import { PAGINATION_OPTIONS } from "constants/pagination";

export const initState: ContractsStateSlice = {
  list: [],
  listRequestStatus: RequestStatus.UNCALLED,
  createRequest: RequestStatus.UNCALLED,
  deleteRequestStatus: RequestStatus.UNCALLED,
  contractIdToDelete: null,
  createRequestErrorCode: null,
  deleteRequestErrorCode: null,
  sortOrder: "desc",
  sortField: "",
  page: 1,
  perPage: PAGINATION_OPTIONS[0].value,
  search: "",
};

export default function ContractsReducer(
  state: ContractsStateSlice = initState,
  action: ContractAction
): ContractsStateSlice {
  switch (action.type) {
    case CONTRACT_LIST_REQUEST:
      return {
        ...state,
        listRequestStatus: RequestStatus.PENDING,
      };
    case CONTRACT_LIST_REQUEST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        listRequestStatus: RequestStatus.SUCCESS,
      };
    }
    case CONTRACT_LIST_REQUEST_FAILED: {
      return {
        ...state,
        listRequestStatus: RequestStatus.FAILURE,
      };
    }
    case SET_SORT_FIELD: {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
      };
    }
    case SET_CONTRACTS_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case SET_CONTRACTS_PER_PAGE: {
      return {
        ...state,
        perPage: action.payload,
        page: 1,
      };
    }
    case SET_CONTRACTS_SEARCH: {
      return {
        ...state,
        search: action.payload,
        page: 1,
      };
    }
    case CONTRACT_CREATE_REQUEST: {
      return {
        ...state,
        createRequest: RequestStatus.PENDING,
        createRequestErrorCode: null,
      };
    }
    case CONTRACT_CREATE_REQUEST_SUCCESS: {
      return {
        ...state,
        createRequest: RequestStatus.SUCCESS,
      };
    }
    case CONTRACT_CREATE_REQUEST_FAILURE: {
      return {
        ...state,
        createRequest: RequestStatus.FAILURE,
        createRequestErrorCode: action.payload,
      };
    }
    case CONTRACT_DELETE_REQUEST: {
      return {
        ...state,
        deleteRequestStatus: RequestStatus.PENDING,
        deleteRequestErrorCode: null,
      };
    }
    case CONTRACT_DELETE_REQUEST_FAILURE: {
      return {
        ...state,
        deleteRequestStatus: RequestStatus.FAILURE,
        deleteRequestErrorCode: action.payload,
      };
    }
    case CONTRACT_DELETE_REQUEST_SUCCESS: {
      return {
        ...state,
        deleteRequestStatus: RequestStatus.SUCCESS,
        list: state.list.filter((item) => item.contractId !== action.payload),
        contractIdToDelete: null,
      };
    }
    case SET_CONTRACT_ID_TO_DELETE: {
      return {
        ...state,
        contractIdToDelete: action.payload,
        deleteRequestErrorCode: null,
        deleteRequestStatus: RequestStatus.UNCALLED,
      };
    }
    default:
      return state;
  }
}
