import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Text, Button, Space } from "@skyportal/ui-kit";
import { ContractListItem } from "types/contracts.types";

interface ContractMappingsCellProps {
  record: ContractListItem;
  showCount?: number;
}

export const ContractMappingsCell: React.FC<ContractMappingsCellProps> = ({ record, showCount = 3 }) => {
  const { t } = useTranslation("dm-common");
  const history = useHistory();
  const isShowMoreBtnVisible = record.mappings.length > showCount;

  return (
    <Space divider=", ">
      {record.mappings.slice(0, showCount).map((item) => (
        <Text key={item.id}>{item.domain}</Text>
      ))}
      {isShowMoreBtnVisible && (
        <Button type="tertiary" onClick={() => history.push("/backup/contracts/" + record.contractId)}>
          {t("Show more", "Show more")}...
        </Button>
      )}
    </Space>
  );
};

export default ContractMappingsCell;
