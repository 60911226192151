import { ContractListItem, ContractFull } from "types/contracts.types";
import { apiClient } from "./clients";

export const getContractList = () =>
  apiClient.request<{ data: ContractListItem[] }>({
    method: "GET",
    url: "/v1/contract",
  });

export const getContractDetails = (id: string) =>
  apiClient.request<ContractFull>({
    method: "GET",
    url: `/v1/contract/${id}`,
  });

export type CreateContractPayload = {
  contractId: string;
};

export const createContract = (data: CreateContractPayload) =>
  apiClient.request<ContractListItem>({
    method: "POST",
    url: "/v1/contract",
    data,
  });

export const deleteContract = (id: string) =>
  apiClient.request({
    method: "DELETE",
    url: `/v1/contract/${id}`,
  });

export type CreateContractMappingPayload = {
  domain: string;
};

export const addContractMapping = (contractId: string, data: CreateContractMappingPayload) =>
  apiClient.request({
    method: "POST",
    url: `/v1/contract/${contractId}/mapping`,
    data,
  });

export type UpdateContractMappingPayload = {
  domain: string;
};

export const updateContractMapping = (contractId: string, domainId: string, data: UpdateContractMappingPayload) =>
  apiClient.request({
    method: "PUT",
    url: `/v1/contract/${contractId}/mapping/${domainId}`,
    data,
  });

export const deleteContractMapping = (contractId: string, domainId: string) =>
  apiClient.request({
    method: "DELETE",
    url: `/v1/contract/${contractId}/mapping/${domainId}`,
  });
