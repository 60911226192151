import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { appActions, appSelectors } from "store/app";

const useCheckUser = () => {
  const thunkDispatch = useThunkDispatch();
  const isUserChecked = useSelector(appSelectors.getIsUserChecked);

  useEffect(() => {
    thunkDispatch(appActions.getUserInfoRequest());
  }, [thunkDispatch]);

  return isUserChecked;
};

export default useCheckUser;
