import {
  AppAction,
  AppStateSlice,
  SET_CREATE_CONTRACT_MODAL_OPEN,
  SET_USER_CHECKED,
  SET_USER_PROFILE,
} from "./app.types";
import { getUserProfileSkeleton } from "@skyportal/auth-web-client";

const initState: AppStateSlice = {
  isCreateContractModalOpen: false,
  isUserChecked: false,
  userProfile: getUserProfileSkeleton(),
};

export default function AppReducer(state: AppStateSlice = initState, action: AppAction): AppStateSlice {
  switch (action.type) {
    case SET_USER_CHECKED: {
      return {
        ...state,
        isUserChecked: action.payload,
      };
    }
    case SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.payload,
      };
    }
    case SET_CREATE_CONTRACT_MODAL_OPEN: {
      return {
        ...state,
        isCreateContractModalOpen: action.payload,
      };
    }
    default:
      return state;
  }
}
