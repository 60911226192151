import i18n from "i18next";

export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

export const getBreadcrumbsConfig = (): BreadcrumbsConfigItem[] => {
  const StartPage: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:start"),
    path: "/start",
  };

  const Backup: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:backup"),
    path: "/backup",
  };

  const Contrancts: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:contracts"),
    path: "/backup/contracts",
  };

  const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
    {
      label: Contrancts.label,
      crumbs: [StartPage, Backup],
      match: {
        path: Contrancts.path,
        exact: true,
      },
    },
  ];

  return breadcrumbsConfig;
};

export const getBreadcrumbsConfigWithContractName = (id: string): BreadcrumbsConfigItem[] => {
  const StartPage: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:start"),
    path: "/start",
  };

  const Backup: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:backup"),
    path: "/backup",
  };

  const Contrancts: BreadcrumbsItem = {
    label: i18n.t("breadcrumbs:contracts"),
    path: "/backup/contracts",
  };

  const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
    {
      label: id,
      crumbs: [StartPage, Backup, Contrancts],
      match: {
        path: Contrancts.path,
      },
    },
  ];

  return breadcrumbsConfig;
};
