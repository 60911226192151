import React, { useEffect } from "react";
import { useForm, RegisterOptions } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, TextInput, FormControl, Button, Modal } from "@skyportal/ui-kit";
import { useAppDispatch, useThunkDispatch } from "store";
import { appActions, appSelectors } from "store/app";
import { contractsSelectors, contractsThunks } from "store/contracts";
import useApiErrorSelector from "hooks/useApiErrorSelector";
import { RequestStatus } from "types/common.types";
import { ContractBaseFormFields } from "types/forms";

export const CreateContractModal: React.FC = () => {
  const { t } = useTranslation("createContract");
  const appDispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const { register, handleSubmit, errors, setError } = useForm<ContractBaseFormFields>();
  const isModalOpen = useSelector(appSelectors.getIsCreateContractModalOpen);
  const requestStatus = useSelector(contractsSelectors.getCreateRequestStatus);
  const apiErrorCodeText = useApiErrorSelector(contractsSelectors.getCreateRequestErrorCode);
  const textInputRef = register<HTMLInputElement>({
    required: t("validations.required"),
    pattern: { value: /^\w\w\d{1,5}$/, message: t("validations.invalidContractIdFormat") },
  } as RegisterOptions);

  const handleCloseModal = () => appDispatch(appActions.setCreateOpenModalOpenAC(false));

  const submit = (data: ContractBaseFormFields) => {
    thunkDispatch(contractsThunks.createContractRequest({ contractId: data.contractId.toUpperCase() }));
  };

  useEffect(() => {
    if (apiErrorCodeText) {
      setError("contractId", { message: apiErrorCodeText });
    }
  }, [apiErrorCodeText, setError]);

  useEffect(() => {
    if (requestStatus === RequestStatus.SUCCESS) {
      appDispatch(appActions.setCreateOpenModalOpenAC(false));
    }
  }, [appDispatch, requestStatus]);

  return (
    <Modal
      loading={requestStatus === RequestStatus.PENDING}
      open={isModalOpen}
      onClose={handleCloseModal}
      title={t("title")}
      data-testid="create-contract-modal"
      controls={
        <>
          <Button type="secondary" onClick={handleCloseModal}>
            {t("dm-common:Cancel")}
          </Button>
          <Button htmlType="submit" form="create-contract" disabled={Object.keys(errors).length > 0}>
            {t("dm-common:Create")}
          </Button>
        </>
      }
    >
      <Grid container direction="column">
        <Grid item>
          <form id="create-contract" onSubmit={handleSubmit(submit)} noValidate>
            <FormControl label={t("fields.contractId")} error={errors.contractId?.message} fullWidth>
              <TextInput
                placeholder="AB12345"
                autoComplete="new-password"
                autoFocus
                ref={textInputRef}
                name="contractId"
              />
            </FormControl>
          </form>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default React.memo(CreateContractModal);
