import React, { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, TextInput, Button } from "@skyportal/ui-kit";
import { useAppDispatch } from "store";
import { contractsActions, contractsSelectors } from "store/contracts";
import { appActions } from "store/app";
import { makeEqByPropList } from "utils/equality";

const getSliceEq = makeEqByPropList(["search"]);

export const ContractsListHeader: React.FC = () => {
  const { t } = useTranslation("dm-common");
  const appDispatch = useAppDispatch();
  const { search } = useSelector(contractsSelectors.getSlice, getSliceEq);

  const handleSearchChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      appDispatch(contractsActions.setContractsSearchAC(event.currentTarget.value));
    },
    [appDispatch]
  );
  const handleCreateBtnClick = useCallback(() => {
    appDispatch(appActions.setCreateOpenModalOpenAC(true));
  }, [appDispatch]);

  return (
    <Grid data-testid="controls" container item spacing={1} justify="flex-start" alignItems="center">
      <Grid item style={{ flexGrow: 1 }}>
        <TextInput placeholder={t("Search", "Search")} defaultValue={search} onChange={handleSearchChange} />
      </Grid>
      <Grid item>
        <Button onClick={handleCreateBtnClick}>{t("Create new", "Create new")}</Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(ContractsListHeader);
