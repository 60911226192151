import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { useLocation, matchPath, useHistory, useParams } from "react-router-dom";
import { makeTitleFromBreadcrumbs } from "./utils";
import { getBreadcrumbsConfig, getBreadcrumbsConfigWithContractName } from "./config";
import { Breadcrumbs } from "@skyportal/ui-kit";

const ModuleBreadcrumbs = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const configItem = id
    ? getBreadcrumbsConfigWithContractName(id).find((item) => matchPath(pathname, item.match))
    : getBreadcrumbsConfig().find((item) => matchPath(pathname, item.match));

  if (!configItem) {
    return null;
  }

  const documentTitle = makeTitleFromBreadcrumbs(configItem);

  return (
    <>
      <Helmet>
        <title>{documentTitle}</title>
      </Helmet>
      <Breadcrumbs config={configItem} onBreadcrumbClick={history.push} />
    </>
  );
};

export default ModuleBreadcrumbs;
