import * as contractsApi from "api/contrats.api";
import { AppThunk } from "store";
import { getAxiosValidationCode } from "api/utils";
import * as actions from "./contract.actions";
import { AxiosError } from "axios";

export const contractDetailsRequest =
  (id: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.contractDetailsRequestAC());
      const { data } = await contractsApi.getContractDetails(id);
      dispatch(actions.contractDetailsRequestSuccessAC(data));
    } catch (error) {
      dispatch(actions.contractDetailsRequestFailureAC());
    }
  };

export const addMappingRequest =
  (id: string, data: contractsApi.CreateContractMappingPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.addMappingRequestAC());
      await contractsApi.addContractMapping(id, data);
      await dispatch(contractDetailsRequest(id));
      dispatch(actions.addMappingRequestSuccessAC());
    } catch (error) {
      const [code] = getAxiosValidationCode(error as AxiosError<unknown, any>);
      dispatch(actions.addMappingRequestFailureAC(code));
    }
  };

export const deleteMappingRequest =
  (contractId: string, domainId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.deleteMappingRequestAC());
      await contractsApi.deleteContractMapping(contractId, domainId);
      dispatch(actions.deleteMappingRequestSuccessAC(domainId));
    } catch (error) {
      const [code] = getAxiosValidationCode(error as AxiosError<unknown, any>);
      dispatch(actions.deleteMappingRequestFailureAC(code));
    }
  };

export const updateMappingRequest =
  (contractId: string, domainId: string, data: contractsApi.UpdateContractMappingPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(actions.updateMappingRequestAC());
      await contractsApi.updateContractMapping(contractId, domainId, data);
      await dispatch(contractDetailsRequest(contractId));
      dispatch(actions.updateMappingRequestSuccessAC());
    } catch (error) {
      const [code] = getAxiosValidationCode(error as AxiosError<unknown, any>);
      dispatch(actions.updateMappingRequestFailureAC(code));
    }
  };
