import React, { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, Button, Alert, Modal } from "@skyportal/ui-kit";
import { useAppDispatch, useThunkDispatch } from "store";
import { contractActions, contractSelectors, contractThunks } from "store/contract";
import { RequestStatus } from "types/common.types";
import { DomainBaseFormFields } from "types/forms";
import useApiErrorSelector from "hooks/useApiErrorSelector";
import DomainBaseForm from "containers/DomainBaseForm";

export const UpdateMappingModal: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("updateMapping");
  const appDispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const updateId = useSelector(contractSelectors.getUpdateMappingId);
  const requestStatus = useSelector(contractSelectors.getUpdateMappingRequest);
  const apiErrorCodeText = useApiErrorSelector(contractSelectors.getUpdateMappingRequestError);
  const apiErrors = useMemo(() => ({ domain: apiErrorCodeText }), [apiErrorCodeText]);
  const domainName = useSelector(contractSelectors.getUpdateMappingName);
  const initValues = useMemo(() => ({ domain: domainName }), [domainName]);

  const handleCloseModal = () => appDispatch(contractActions.setUpdateMappingIdAC(null));

  const handleSubmit = useCallback(
    (data: DomainBaseFormFields) => {
      if (updateId !== null) {
        thunkDispatch(contractThunks.updateMappingRequest(id, updateId, { domain: data.domain.toLowerCase() }));
      }
    },
    [thunkDispatch, updateId, id]
  );

  return (
    <Modal
      loading={requestStatus === RequestStatus.PENDING}
      open={updateId !== null}
      onClose={handleCloseModal}
      title={`${t("title")} "${domainName}"`}
      data-testid="update-mapping-modal"
      controls={
        <>
          <Button type="secondary" onClick={handleCloseModal}>
            {t("dm-common:Cancel")}
          </Button>
          <Button htmlType="submit" form="update-mapping">
            {t("dm-common:Save")}
          </Button>
        </>
      }
    >
      <Grid container direction="column">
        <Grid item>
          <DomainBaseForm id="update-mapping" onSubmit={handleSubmit} apiErrors={apiErrors} initValues={initValues} />
        </Grid>
        {requestStatus === RequestStatus.FAILURE && !apiErrorCodeText && (
          <Grid item>
            <Alert type="error">{t("defaultErrorMessage")}</Alert>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default React.memo(UpdateMappingModal);
