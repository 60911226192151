import React from "react";
import { Link } from "react-router-dom";
import { Button, Title } from "@skyportal/ui-kit";
import styles from "./styles.module.css";
import ModuleBreadcrumbs from "components/ModuleBreadcrumbs";

export interface PageHeaderProps {
  title: string;
  isContractPage?: boolean;
}

export const PageHeader: React.FC<PageHeaderProps> = ({ title, isContractPage }) => {
  const ContractPreviewHeader = () => {
    return (
      <>
        <ModuleBreadcrumbs />
        <div className={styles.header}>
          <Link to="/backup/contracts">
            <Button leftIcon="arrow-left"></Button>
          </Link>
          <Title level={2}>{title}</Title>
        </div>
      </>
    );
  };

  return (
    <header className={styles.root}>
      {isContractPage ? (
        <ContractPreviewHeader />
      ) : (
        <>
          <ModuleBreadcrumbs />
          <Title level={2}>{title}</Title>
        </>
      )}
    </header>
  );
};

export default React.memo(PageHeader);
