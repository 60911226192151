import React from "react";
import { Grid, Button } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { DomainMappingFull } from "types/contracts.types";
import { contractActions } from "../../store/contract";
import { useAppDispatch } from "../../store";

interface MappingActionCellProps {
  record: DomainMappingFull;
}

export const MappingActionCell: React.FC<MappingActionCellProps> = ({ record }) => {
  const appDispatch = useAppDispatch();
  const { t } = useTranslation("dm-common");

  const handleEditClick = () => appDispatch(contractActions.setUpdateMappingIdAC(record.id));
  const handleDeleteClick = () => appDispatch(contractActions.setDeleteMappingIdAC(record.id));

  return (
    <Grid container spacing={1} alignItems="center" justify="flex-end">
      <Grid item>
        <Button size="small" onClick={handleEditClick}>
          {t("Edit", "Edit")}
        </Button>
      </Grid>
      <Grid item>
        <Button type="secondary" size="small" onClick={handleDeleteClick}>
          {t("Delete", "Delete")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default React.memo(MappingActionCell);
