import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "store";
import { PAGINATION_OPTIONS } from "constants/pagination";
import { contractsActions, contractsSelectors } from "store/contracts";
import { Grid, Pagination, Dropdown } from "@skyportal/ui-kit";
import { makeEqByPropList } from "utils/equality";

const getSliceEq = makeEqByPropList(["page", "perPage"]);
const getContractTableEq = makeEqByPropList(["pageCount"]);

export const ContractsListFooter: React.FC = () => {
  const appDispatch = useAppDispatch();
  const { page, perPage } = useSelector(contractsSelectors.getSlice, getSliceEq);
  const { pageCount } = useSelector(contractsSelectors.getContractTable, getContractTableEq);

  const handlePerPageChange = useCallback(
    (value: string) => {
      appDispatch(contractsActions.setContractsPerPageAC(Number(value)));
    },
    [appDispatch]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      appDispatch(contractsActions.setContractsPageAC(page));
    },
    [appDispatch]
  );
  return (
    <Grid data-testid="pagination" container item justify="flex-end" spacing={1} alignItems="center">
      <Grid item>
        <Dropdown options={PAGINATION_OPTIONS} onChange={handlePerPageChange} value={perPage} />
      </Grid>
      <Grid item>
        <Pagination onChange={handlePageChange} page={page} count={pageCount} />
      </Grid>
    </Grid>
  );
};

export default React.memo(ContractsListFooter);
