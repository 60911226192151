import {
  ADD_MAPPING_REQUEST,
  ADD_MAPPING_REQUEST_FAILURE,
  ADD_MAPPING_REQUEST_SUCCESS,
  AddMappingRequestAC,
  AddMappingRequestFailureAC,
  AddMappingRequestSuccessAC,
  CONTRACT_DETAILS_REQUEST,
  CONTRACT_DETAILS_REQUEST_FAILURE,
  CONTRACT_DETAILS_REQUEST_SUCCESS,
  ContractDetailsRequestAC,
  ContractDetailsRequestFailureAC,
  ContractDetailsRequestSuccessAC,
  DELETE_CONTRACT_MAPPING_REQUEST,
  DELETE_CONTRACT_MAPPING_REQUEST_FAILURE,
  DELETE_CONTRACT_MAPPING_REQUEST_SUCCESS,
  DeleteMappingRequestAC,
  DeleteMappingRequestFailureAC,
  DeleteMappingRequestSuccessAC,
  SET_ADD_MAPPING_MODAL_OPEN,
  SET_DELETE_CONTRACT_MAPPING_ID,
  SET_UPDATE_CONTRACT_MAPPING_ID,
  SetAddMappingModalOpenAC,
  SetDeleteMappingIdAC,
  SetUpdateMappingIdAC,
  UPDATE_CONTRACT_MAPPING_REQUEST,
  UPDATE_CONTRACT_MAPPING_REQUEST_FAILURE,
  UPDATE_CONTRACT_MAPPING_REQUEST_SUCCESS,
  UpdateMappingRequestAC,
  UpdateMappingRequestFailureAC,
  UpdateMappingRequestSuccessAC,
} from "./contract.types";

export const contractDetailsRequestAC: ContractDetailsRequestAC = () => ({
  type: CONTRACT_DETAILS_REQUEST,
});

export const contractDetailsRequestSuccessAC: ContractDetailsRequestSuccessAC = (payload) => ({
  type: CONTRACT_DETAILS_REQUEST_SUCCESS,
  payload,
});

export const contractDetailsRequestFailureAC: ContractDetailsRequestFailureAC = () => ({
  type: CONTRACT_DETAILS_REQUEST_FAILURE,
});

export const setAddMappingModalOpenAC: SetAddMappingModalOpenAC = (payload) => ({
  type: SET_ADD_MAPPING_MODAL_OPEN,
  payload,
});

export const addMappingRequestAC: AddMappingRequestAC = () => ({
  type: ADD_MAPPING_REQUEST,
});

export const addMappingRequestFailureAC: AddMappingRequestFailureAC = (payload) => ({
  type: ADD_MAPPING_REQUEST_FAILURE,
  payload,
});

export const addMappingRequestSuccessAC: AddMappingRequestSuccessAC = () => ({
  type: ADD_MAPPING_REQUEST_SUCCESS,
});

export const deleteMappingRequestAC: DeleteMappingRequestAC = () => ({
  type: DELETE_CONTRACT_MAPPING_REQUEST,
});

export const deleteMappingRequestSuccessAC: DeleteMappingRequestSuccessAC = (payload) => ({
  type: DELETE_CONTRACT_MAPPING_REQUEST_SUCCESS,
  payload,
});

export const deleteMappingRequestFailureAC: DeleteMappingRequestFailureAC = (payload) => ({
  type: DELETE_CONTRACT_MAPPING_REQUEST_FAILURE,
  payload,
});

export const setDeleteMappingIdAC: SetDeleteMappingIdAC = (payload) => ({
  type: SET_DELETE_CONTRACT_MAPPING_ID,
  payload,
});

export const updateMappingRequestAC: UpdateMappingRequestAC = () => ({
  type: UPDATE_CONTRACT_MAPPING_REQUEST,
});

export const updateMappingRequestSuccessAC: UpdateMappingRequestSuccessAC = () => ({
  type: UPDATE_CONTRACT_MAPPING_REQUEST_SUCCESS,
});

export const updateMappingRequestFailureAC: UpdateMappingRequestFailureAC = (payload) => ({
  type: UPDATE_CONTRACT_MAPPING_REQUEST_FAILURE,
  payload,
});

export const setUpdateMappingIdAC: SetUpdateMappingIdAC = (payload) => ({
  type: SET_UPDATE_CONTRACT_MAPPING_ID,
  payload,
});
