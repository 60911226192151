import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { RegisterOptions, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { TextInput, FormControl } from "@skyportal/ui-kit";
import { DomainBaseFormFields } from "types/forms";
import { contractSelectors } from "store/contract";

interface DomainBaseFormProps {
  id: string;
  initValues?: DomainBaseFormFields;
  apiErrors?: DomainBaseFormFields;
  onSubmit: (data: DomainBaseFormFields) => void;
}

export const DomainBaseForm: React.FC<DomainBaseFormProps> = ({ id, onSubmit, initValues, apiErrors }) => {
  const { t } = useTranslation("domainBaseForm");
  const mappings = useSelector(contractSelectors.getContractMappings);
  const { register, handleSubmit, errors, setError } = useForm<DomainBaseFormFields>();
  const textInputRef = register<HTMLInputElement>({
    required: t("validations.required"),
    pattern: { value: /^[A-Za-z0-9.-]{2,}\.[A-Za-z]{2,4}$/, message: t("validations.invalidFormat") },
    validate: (domain: string) => {
      if (mappings.some((item) => item.domain === domain.toLowerCase())) {
        return t("validations.domainAlreadyExist");
      }
      return true;
    },
  } as RegisterOptions);

  useEffect(() => {
    if (apiErrors?.domain) {
      setError("domain", { message: apiErrors.domain });
    }
  }, [apiErrors?.domain, setError]);

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormControl label={t("fields.domain")} error={errors.domain?.message} fullWidth>
        <TextInput
          defaultValue={initValues?.domain}
          autoFocus
          placeholder={t("domainPlaceholder")}
          autoComplete="new-password"
          ref={textInputRef}
          name="domain"
        />
      </FormControl>
    </form>
  );
};

export default React.memo(DomainBaseForm);
