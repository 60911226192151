import { combineReducers } from "redux";
import { reducer as app } from "./app";
import { reducer as contracts } from "./contracts";
import { reducer as contract } from "./contract";

const rootReducer = combineReducers({
  app,
  contracts,
  contract,
});

export default rootReducer;
