import * as React from "react";
import { useParams } from "react-router-dom";
import PageLayout from "layouts/PageLayout";
import DomainList from "containers/DomainList";
import PageHeader from "components/PageHeader";
import CreateMappingModal from "modals/CreateMappingModal";
import DeleteMappingModal from "modals/DeleteMappingModal";
import UpdateMappingModal from "modals/UpdateMappingModal";

export const ContractPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  return (
    <PageLayout>
      <PageHeader title={id} isContractPage />
      <DomainList />
      <CreateMappingModal />
      <DeleteMappingModal />
      <UpdateMappingModal />
    </PageLayout>
  );
};

export default ContractPage;
