import * as React from "react";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "components/PageHeader";
import ContractList from "containers/ContractList";
import CreateContractModal from "modals/CreateContractModal";
import DeleteContractModal from "modals/DeleteContractModal";

export const ContractsPage: React.FC = () => {
  const { t } = useTranslation("contractsPage");

  return (
    <PageLayout>
      <PageHeader title={t("title")} />
      <ContractList />
      <CreateContractModal />
      <DeleteContractModal />
    </PageLayout>
  );
};

export default ContractsPage;
