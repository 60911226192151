import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Grid, Button, Text, Alert, Modal, Space } from "@skyportal/ui-kit";
import { useAppDispatch, useThunkDispatch } from "store";
import useApiErrorSelector from "hooks/useApiErrorSelector";
import { contractsActions, contractsSelectors, contractsThunks } from "store/contracts";
import { RequestStatus } from "types/common.types";

export const DeleteContractModal: React.FC = () => {
  const { t } = useTranslation("deleteContract");
  const apiErrorMessage = useApiErrorSelector(contractsSelectors.getDeleteRequestErrorCode);
  const appDispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const requestStatus = useSelector(contractsSelectors.getDeleteRequestStatus);
  const contractIdToDelete = useSelector(contractsSelectors.getDeleteContractId);
  const handleCloseModal = () => appDispatch(contractsActions.setContractIdToDeleteAC(null));

  const handleDeleteConfirm = () => {
    if (contractIdToDelete !== null) {
      thunkDispatch(contractsThunks.deleteContractRequest(contractIdToDelete));
    }
  };

  return (
    <Modal
      loading={requestStatus === RequestStatus.PENDING}
      open={contractIdToDelete !== null}
      onClose={handleCloseModal}
      title={t("title")}
      data-testid="delete-contract-modal"
      controls={
        <>
          <Button type="secondary" onClick={handleCloseModal}>
            {t("dm-common:No")}
          </Button>
          <Button onClick={handleDeleteConfirm}>{t("dm-common:Yes")}</Button>
        </>
      }
    >
      <Grid container direction="column">
        <Grid item>
          <Space divider=" ">
            <Text>{t("hintStart")}</Text>
            <Text type="danger">"{contractIdToDelete}"</Text>
            <Text>{t("hintEnd")}</Text>
          </Space>
        </Grid>
        {requestStatus === RequestStatus.FAILURE && (
          <Grid item>
            <Alert type="error">{apiErrorMessage || t("defaultErrorMessage")}</Alert>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default React.memo(DeleteContractModal);
