import * as contractApi from "api/contrats.api";
import { getAxiosValidationCode } from "api/utils";
import { AxiosError } from "axios";
import { AppThunk } from "store";
import {
  contractCreateRequestAC,
  contractCreateRequestFailureAC,
  contractCreateRequestSuccessAC,
  contractListRequestAC,
  contractListRequestFailedAC,
  contractListRequestSuccessAC,
  contractDeleteRequestAC,
  contractDeleteRequestSuccessAC,
  contractDeleteRequestFailureAC,
} from "./contracts.actions";

export const listRequest = (): AppThunk => async (dispatch) => {
  try {
    dispatch(contractListRequestAC());
    const response = await contractApi.getContractList();
    dispatch(contractListRequestSuccessAC(response.data.data));
  } catch (error) {
    dispatch(contractListRequestFailedAC());
  }
};

export const createContractRequest =
  (data: contractApi.CreateContractPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(contractCreateRequestAC());
      await contractApi.createContract(data);
      await dispatch(listRequest());
      dispatch(contractCreateRequestSuccessAC());
    } catch (error) {
      const [code] = getAxiosValidationCode(error as AxiosError<unknown, any>);
      dispatch(contractCreateRequestFailureAC(code));
    }
  };

export const deleteContractRequest =
  (contractId: string): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(contractDeleteRequestAC());
      await contractApi.deleteContract(contractId);
      dispatch(contractDeleteRequestSuccessAC(contractId));
    } catch (error) {
      const [code] = getAxiosValidationCode(error as AxiosError<unknown, any>);
      dispatch(contractDeleteRequestFailureAC(code));
    }
  };
