import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { TableProps, Text } from "@skyportal/ui-kit";
import { DomainMappingFull } from "types/contracts.types";
import { MappingActionCell } from "./MappingActionCell";
import { formatDate, formatTime } from "../../utils/time";

const useColumnConfig = () => {
  const { t } = useTranslation("dm-common");

  const columns: TableProps<DomainMappingFull>["columns"] = useMemo(
    () => [
      {
        dataIndex: "domain",
        key: "domain",
        sortDisabled: true,
        title: t("Domain", "Domain"),
        render: (value: string) => <Text>{value}</Text>,
      },
      {
        dataIndex: "lastUpdated",
        key: "lastUpdated",
        sortDisabled: true,
        title: t("Last Updated", "Last Updated"),
        render: (value: string) => (
          <Text>
            {formatDate(value)} {formatTime(value)}
          </Text>
        ),
      },
      {
        dataIndex: "_action",
        key: "actions",
        sortDisabled: true,
        title: "",
        render: (_: any, record: DomainMappingFull) => <MappingActionCell record={record} />,
      },
    ],
    [t]
  );

  return columns;
};

export default useColumnConfig;
