import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useThunkDispatch } from "store";
import { contractsSelectors, contractsThunks } from "store/contracts";
import { RequestStatus } from "types/common.types";

const useFetchContracts = (): boolean => {
  const thunkDispatch = useThunkDispatch();
  const requestStatus = useSelector(contractsSelectors.getListRequestStatus);

  useEffect(() => {
    thunkDispatch(contractsThunks.listRequest());
  }, [thunkDispatch]);

  return requestStatus === RequestStatus.PENDING;
};

export default useFetchContracts;
