import axios from "axios";
import config from "config";
import createAuthClient, { isForbiddenError, isUnauthorizedError, redirectToAuth } from "@skyportal/auth-web-client";

export const authClient = createAuthClient({
  authApiURL: config.authApiUrl,
  authWebURL: config.authWebUrl,
});

export const apiClient = axios.create({
  baseURL: config.apiUrl,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

apiClient.interceptors.response.use(
  (response) => response,
  (response) => {
    if (isUnauthorizedError(response)) {
      redirectToAuth(config.authWebUrl);
    } else if (isForbiddenError(response)) {
      window.location.href = config.baseUrl;
    }

    return Promise.reject(response);
  }
);
