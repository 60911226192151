import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Table } from "@skyportal/ui-kit";
import { useAppDispatch } from "store";
import { contractsActions, contractsSelectors } from "store/contracts";
import { ContractSortFiled } from "types/contracts.types";
import { makeEqByPropList } from "utils/equality";
import useColumnConfig from "./useColumnConfig";

const getSliceEq = makeEqByPropList(["sortOrder", "sortField", "search"]);
const getContractTableEq = makeEqByPropList(["pageItems"]);

export const ContractsTable: React.FC = () => {
  const { t } = useTranslation("dm-common");
  const appDispatch = useAppDispatch();
  const columns = useColumnConfig();
  const { sortOrder, sortField, search } = useSelector(contractsSelectors.getSlice, getSliceEq);
  const { pageItems } = useSelector(contractsSelectors.getContractTable, getContractTableEq);

  const onSortFieldChange = useCallback(
    (sortFiled: string) => appDispatch(contractsActions.setSortFieldAC(sortFiled as ContractSortFiled)),
    [appDispatch]
  );
  return (
    <Table
      size="medium"
      noDataText={search ? t("No search results") : t("No contracts yet")}
      sortField={sortField}
      rowKey="contractId"
      sortOrder={sortOrder}
      columns={columns}
      dataSource={pageItems}
      onSortFieldChange={onSortFieldChange}
    />
  );
};

export default React.memo(ContractsTable);
