import { ContractFull } from "types/contracts.types";
import { ApiErrorCode, RequestStatus } from "types/common.types";

export interface ContractStateSlice {
  readonly detailed: ContractFull | null;
  readonly getRequestStatus: RequestStatus;
  readonly addMappingModalOpen: boolean;
  readonly addMappingRequest: RequestStatus;
  readonly addMappingRequestError: ApiErrorCode;
  readonly deleteMappingId: string | null;
  readonly deleteMappingRequest: RequestStatus;
  readonly deleteMappingRequestError: ApiErrorCode;
  readonly updateMappingId: string | null;
  readonly updateMappingRequest: RequestStatus;
  readonly updateMappingRequestError: ApiErrorCode;
}

export const CONTRACT_DETAILS_REQUEST = "CONTRACT/GET_REQUEST";
export type ContractDetailsRequestAction = {
  type: typeof CONTRACT_DETAILS_REQUEST;
};
export type ContractDetailsRequestAC = () => ContractDetailsRequestAction;

export const CONTRACT_DETAILS_REQUEST_SUCCESS = "CONTRACT/CONTRACT_DETAILS_REQUEST_SUCCESS";
export type ContractDetailsRequestSuccessAction = {
  type: typeof CONTRACT_DETAILS_REQUEST_SUCCESS;
  payload: ContractFull;
};
export type ContractDetailsRequestSuccessAC = (payload: ContractFull) => ContractDetailsRequestSuccessAction;

export const CONTRACT_DETAILS_REQUEST_FAILURE = "CONTRACT/CONTRACT_DETAILS_REQUEST_FAILURE";
export type ContractDetailsRequestFailureAction = {
  type: typeof CONTRACT_DETAILS_REQUEST_FAILURE;
};
export type ContractDetailsRequestFailureAC = () => ContractDetailsRequestFailureAction;

export const ADD_MAPPING_REQUEST = "CONTRACT/ADD_MAPPING_REQUEST";
export type AddMappingRequestAction = {
  type: typeof ADD_MAPPING_REQUEST;
};
export type AddMappingRequestAC = () => AddMappingRequestAction;

export const ADD_MAPPING_REQUEST_SUCCESS = "CONTRACT/ADD_MAPPING_REQUEST_SUCCESS";
export type AddMappingRequestSuccessAction = {
  type: typeof ADD_MAPPING_REQUEST_SUCCESS;
};
export type AddMappingRequestSuccessAC = () => AddMappingRequestSuccessAction;

export const ADD_MAPPING_REQUEST_FAILURE = "CONTRACT/ADD_MAPPING_REQUEST_FAILURE";
export type AddMappingRequestFailureAction = {
  type: typeof ADD_MAPPING_REQUEST_FAILURE;
  payload: ApiErrorCode;
};
export type AddMappingRequestFailureAC = (payload: ApiErrorCode) => AddMappingRequestFailureAction;

export const SET_ADD_MAPPING_MODAL_OPEN = "CONTRACT/SET_ADD_MAPPING_MODAL_OPEN";
export type SetAddMappingModalOpenAction = {
  type: typeof SET_ADD_MAPPING_MODAL_OPEN;
  payload: boolean;
};
export type SetAddMappingModalOpenAC = (payload: boolean) => SetAddMappingModalOpenAction;

export const DELETE_CONTRACT_MAPPING_REQUEST = "CONTRACT/DELETE_CONTRACT_MAPPING_REQUEST";
export type DeleteMappingRequestAction = {
  type: typeof DELETE_CONTRACT_MAPPING_REQUEST;
};
export type DeleteMappingRequestAC = () => DeleteMappingRequestAction;

export const DELETE_CONTRACT_MAPPING_REQUEST_SUCCESS = "CONTRACT/DELETE_CONTRACT_MAPPING_REQUEST_SUCCESS";
export type DeleteMappingRequestSuccessAction = {
  type: typeof DELETE_CONTRACT_MAPPING_REQUEST_SUCCESS;
  payload: string;
};
export type DeleteMappingRequestSuccessAC = (payload: string) => DeleteMappingRequestSuccessAction;

export const DELETE_CONTRACT_MAPPING_REQUEST_FAILURE = "CONTRACT/DELETE_CONTRACT_MAPPING_REQUEST_FAILURE";
export type DeleteMappingRequestFailureAction = {
  type: typeof DELETE_CONTRACT_MAPPING_REQUEST_FAILURE;
  payload: ApiErrorCode;
};
export type DeleteMappingRequestFailureAC = (payload: ApiErrorCode) => DeleteMappingRequestFailureAction;

export const SET_DELETE_CONTRACT_MAPPING_ID = "CONTRACT/SET_DELETE_CONTRACT_MAPPING_ID";
export type SetDeleteMappingIdAction = {
  type: typeof SET_DELETE_CONTRACT_MAPPING_ID;
  payload: string | null;
};
export type SetDeleteMappingIdAC = (domainId: string | null) => SetDeleteMappingIdAction;

export const UPDATE_CONTRACT_MAPPING_REQUEST = "CONTRACT/UPDATE_CONTRACT_MAPPING_REQUEST";
export type UpdateMappingRequestAction = {
  type: typeof UPDATE_CONTRACT_MAPPING_REQUEST;
};
export type UpdateMappingRequestAC = () => UpdateMappingRequestAction;

export const UPDATE_CONTRACT_MAPPING_REQUEST_SUCCESS = "CONTRACT/UPDATE_CONTRACT_MAPPING_REQUEST_SUCCESS";
export type UpdateMappingRequestSuccessAction = {
  type: typeof UPDATE_CONTRACT_MAPPING_REQUEST_SUCCESS;
};
export type UpdateMappingRequestSuccessAC = () => UpdateMappingRequestSuccessAction;

export const UPDATE_CONTRACT_MAPPING_REQUEST_FAILURE = "CONTRACT/UPDATE_CONTRACT_MAPPING_REQUEST_FAILURE";
export type UpdateMappingRequestFailureAction = {
  type: typeof UPDATE_CONTRACT_MAPPING_REQUEST_FAILURE;
  payload: ApiErrorCode;
};
export type UpdateMappingRequestFailureAC = (payload: ApiErrorCode) => UpdateMappingRequestFailureAction;

export const SET_UPDATE_CONTRACT_MAPPING_ID = "CONTRACT/SET_UPDATE_CONTRACT_MAPPING_ID";
export type SetUpdateMappingIdAction = {
  type: typeof SET_UPDATE_CONTRACT_MAPPING_ID;
  payload: string | null;
};
export type SetUpdateMappingIdAC = (domainId: string | null) => SetUpdateMappingIdAction;

export type ContractAction =
  | SetUpdateMappingIdAction
  | UpdateMappingRequestAction
  | UpdateMappingRequestSuccessAction
  | UpdateMappingRequestFailureAction
  | SetDeleteMappingIdAction
  | DeleteMappingRequestAction
  | DeleteMappingRequestSuccessAction
  | DeleteMappingRequestFailureAction
  | SetAddMappingModalOpenAction
  | AddMappingRequestAction
  | AddMappingRequestSuccessAction
  | AddMappingRequestFailureAction
  | ContractDetailsRequestAction
  | ContractDetailsRequestSuccessAction
  | ContractDetailsRequestFailureAction;
