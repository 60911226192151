import { createSelector } from "reselect";
import { RootState } from "../index";
import { makeUniversalSort } from "utils/sort";
import { ContractListItem, ContractSortFiled } from "types/contracts.types";
import { SortOrder } from "types/common.types";

export const getSlice = (state: RootState) => state.contracts;
export const getList = (state: RootState) => state.contracts.list;
export const getSearch = (state: RootState) => state.contracts.search;
export const getPerPage = (state: RootState) => state.contracts.perPage;
export const getPage = (state: RootState) => state.contracts.page;
export const getSortField = (state: RootState) => state.contracts.sortField;
export const getSortOrder = (state: RootState) => state.contracts.sortOrder;
export const getListRequestStatus = (state: RootState) => getSlice(state).listRequestStatus;
export const getCreateRequestStatus = (state: RootState) => getSlice(state).createRequest;
export const getDeleteRequestStatus = (state: RootState) => getSlice(state).deleteRequestStatus;
export const getCreateRequestErrorCode = (state: RootState) => getSlice(state).createRequestErrorCode;
export const getDeleteRequestErrorCode = (state: RootState) => getSlice(state).deleteRequestErrorCode;
export const getDeleteContractId = (state: RootState) => getSlice(state).contractIdToDelete;

const idSortValue = (item: ContractListItem) => item.contractId;
const lastSortValue = (item: ContractListItem) => Number(new Date(item.lastUpdated));
const mappingsSortValue = (item: ContractListItem) => item.mappings.length;

const sortMap: Record<
  Exclude<ContractSortFiled, "">,
  (sort: SortOrder) => (a: ContractListItem, b: ContractListItem) => number
> = {
  contractId: makeUniversalSort(idSortValue),
  lastUpdated: makeUniversalSort(lastSortValue),
  mappings: makeUniversalSort(mappingsSortValue),
};

export const getSearchContractList = createSelector(getList, getSearch, (list, search) => {
  const _search = search.trim().toLowerCase();

  if (_search) {
    return list.filter(
      ({ contractId, mappings }) =>
        contractId.toLowerCase().includes(_search) ||
        mappings.some(({ domain }) => domain.toLowerCase().includes(_search))
    );
  }

  return list;
});

export const getContractTable = createSelector(
  getSearchContractList,
  getPerPage,
  getPage,
  getSortField,
  getSortOrder,
  (_list, perPage, page, sortField, sortOrder) => {
    const list = [..._list];

    if (sortField && sortField in sortMap) {
      list.sort(sortMap[sortField](sortOrder));
    }

    const total = list.length;

    return {
      total,
      pageCount: Math.ceil(total / perPage),
      pageItems: list.slice((page - 1) * perPage, page * perPage),
    };
  }
);
