import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid, Button, Alert, Text, Modal } from "@skyportal/ui-kit";
import useApiErrorSelector from "hooks/useApiErrorSelector";
import { useAppDispatch, useThunkDispatch } from "store";
import { contractActions, contractSelectors, contractThunks } from "store/contract";
import { RequestStatus } from "types/common.types";

export const DeleteMappingModal: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation("deleteMapping");
  const apiErrorMessage = useApiErrorSelector(contractSelectors.getDeleteMappingRequestError);
  const appDispatch = useAppDispatch();
  const thunkDispatch = useThunkDispatch();
  const requestStatus = useSelector(contractSelectors.getDeleteMappingRequest);
  const deleteId = useSelector(contractSelectors.getDeleteMappingId);
  const domainName = useSelector(contractSelectors.getDeleteMappingName);
  const handleCloseModal = () => appDispatch(contractActions.setDeleteMappingIdAC(null));

  const handleDeleteConfirm = () => {
    if (deleteId !== null) {
      thunkDispatch(contractThunks.deleteMappingRequest(id, deleteId));
    }
  };

  return (
    <Modal
      loading={requestStatus === RequestStatus.PENDING}
      open={deleteId !== null}
      onClose={handleCloseModal}
      title={`${t("title")} "${domainName}"`}
      data-testid="delete-mapping-modal"
      controls={
        <>
          <Button type="secondary" onClick={handleCloseModal}>
            {t("dm-common:No")}
          </Button>
          <Button onClick={handleDeleteConfirm}>{t("dm-common:Yes")}</Button>
        </>
      }
    >
      <Grid container direction="column">
        <Grid item>
          <Text>{t("hint")}</Text>
        </Grid>
        {requestStatus === RequestStatus.FAILURE && (
          <Grid item>
            <Alert type="error">{apiErrorMessage || t("defaultErrorMessage")}</Alert>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default React.memo(DeleteMappingModal);
