// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".domainMapper_styles_root__AKPrN {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: var(--padding-medium);\n  gap: 16px;\n}\n\n.domainMapper_styles_header__Yc3xY {\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/PageHeader/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,oCAAoC;EACpC,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,SAAS;AACX","sourcesContent":[".root {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: var(--padding-medium);\n  gap: 16px;\n}\n\n.header {\n  display: flex;\n  align-items: center;\n  margin-bottom: 8px;\n  gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "domainMapper_styles_root__AKPrN",
	"header": "domainMapper_styles_header__Yc3xY"
};
export default ___CSS_LOADER_EXPORT___;
